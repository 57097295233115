import request from "@/utils/request";

// 登录
export const login = (data) => {
  return request({
    url: "/api/app/account/login",
    method: "post",
    data: data,
  });
};

// 微信扫码登录
export const wxLogin = (query) => {
  return request({
    url: "/api/app/wechatLoginCallback",
    method: "get",
    params: query,
  });
};

/**
 * 微信绑定手机号
 * @param { String } bindCode || 绑定码
 * @param { String } phoneNumber || 手机号
 * @param { String } verificationCode || 验证码
 */
export const wechatBind = (data) => {
  return request({
    url: "/api/app/wechatBind",
    method: "post",
    data: data,
  });
};


/**
 * 获取验证码
 * @param { String } phone || 手机号
 */
export const smsCode = (query) => {
  return request({
    url: "/api/app/account/smsCode",
    method: "get",
    params: query
  });
};

/**
 * 获取用户信息
 */
export const getUserInfo = () => {
  return request({
    url: "/api/app/account/info",
    method: "get"
  });
};

/**
 * 忘记密码
 * @param { String } newPassword || 新密码
 * @param { String } verificationCode || 验证码
 * @param { String } phoneNumber || 手机号
 */
export const forgetPassword = (data) => {
  return request({
    url: "/api/app/account/ForgetPassword",
    method: "post",
    data: data,
  });
};


/**
 * 获取隐私协议
 */
export const getPrivacyPolicy = () => {
  return request({
    url: "/api/app/info/PrivacyPolicy",
    method: "get"
  });
};

/**
 * 获取用户协议
 */
export const getUserAgreement = () => {
  return request({
    url: "/api/app/info/UserAgreement",
    method: "get"
  });
};


